import {reactive,ref,unref,toRefs} from "vue"
import {validLength} from "@/utils/validator"
import $api from "@/api"
import Dialog from "@/utils/ui/dialog"
import uploadImg from "@/utils/file/uploadImg"
export default function useReleaseArticle(uploadRef,imgs,isList,getArticleList) {
    const formRef=ref(null)
    const editorRef=ref(null)
    const passage = reactive({
        title: "",
        desc: "",
        content: "",
        img:""
    });
    const rules=reactive({
        title:[{ required: true, trigger: 'blur',name:"文章标题",validator:validLength }],
        desc:[{ required: true, trigger: 'blur',name:"文章摘要",validator:validLength }],
        content:[{ required: true, trigger: 'blur',name:"文章内容",validator:validLength }],
    })
    const inputRichText = (val) => {
        passage.content = val
    }

    const onSubmit = async () => {
        formRef.value.validate(async(valid)=>{
            if(!valid){
                return;
            }else{
                let picUrl=await uploadImg([...imgs.value])
                const params={
                    "id": 0,
                    "title": passage.title,
                    "descrption": passage.desc,
                    "content": passage.content,
                    "image": picUrl,
                    "state": 1,
                    "author": 0,
                    "createdTime": new Date().format("yyyy-MM-dd hh:mm:ss"),
                    "typeIds": [4]
                }
                const [err,data]=await $api.user.releaseArticle(params)
                if(!data.succeeded){
                    Dialog.alert("提示",data.errors,true,true)
                    return;
                }
                editorRef.value.clearContent()
                formRef.value.resetFields()
                uploadRef.value.clearFiles()
                imgs.value=[]
                isList.value=true
                getArticleList()
            }
        })
    }
    return {
        editorRef,
        formRef,
        rules,
        passage,
        onSubmit,
        inputRichText
    }
}