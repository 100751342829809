<template>
  <div class="content">
    <div class="heading_box">
      <div class="dib item tc" :class="{ current: isList }">
        <span class="db auto my-article-item" @click="isList = true">文章列表</span>
      </div>
      <div class="dib item tc" :class="{ current: !isList }">
        <span class="db auto my-article-item" @click="isList = false">文章发布</span>
      </div>
    </div>

    <div class="list_1" v-show="isList">
      <el-table
    ref="multipleTable"
    :data="list"
    tooltip-effect="dark"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    height="400">
    <el-table-column type="selection" width="55"> </el-table-column>
    <el-table-column align="center" type="index" label="序号" width="120"> </el-table-column>
    <el-table-column align="center" prop="title" label="标题" width="500" show-overflow-tooltip> </el-table-column>
    <el-table-column align="center"  width="150" label="发布日期">
      <template #default="scope">
      <span v-formatTime>{{scope.row.createdTime}}</span></template>
    </el-table-column>
    <el-table-column align="center" label="文章状态" width="150">
      <template #default="scope">
        <span >{{!!scope.row.state?"已发布":"审核中"}}</span>
      </template>
    </el-table-column>

     <el-table-column align="right">
      <template #header>
         <i class="iconfont icon-trash-gray fr" @click="removeArticle"></i>
      </template>
      <template #default="scope">
      </template>
    </el-table-column>
  </el-table>

      <div class="pagnation">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.PageIndex"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryParams.PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>

    <div class="list_2 pt30" v-show="!isList">
      <el-form ref="formRef" :model="passage" :rules="rules">
        <el-form-item class="contact_box" prop="title">
          <el-input v-model="passage.title" placeholder="文章标题"></el-input>
        </el-form-item>

        <el-form-item prop="desc">
          <el-input
            type="textarea"
            v-model="passage.desc"
            placeholder="文章摘要"
          ></el-input>
        </el-form-item>

        <span class="f14  mr10">文章缩略图</span
        ><span class="remark">(图像格式为jpg\png，大小不超过2m)</span>
        <el-form-item label="" class="bdb mt5">
          <el-upload
            :limit="1"
            ref="uploadRef"
            class="avatar-uploader"
            :class="{ disable: !!imgs.length }"
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :on-change="fileChange"
          >
            <template #default>
              <i class="el-icon-plus"></i>
            </template>
            <template #file="{file}">
              <div class="operate-container">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePicPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </template>
          </el-upload>

          <el-dialog v-model="preview.show">
            <img width="100%" :src="preview.url" class="preview-img" alt="" />
          </el-dialog>
        </el-form-item>

        <el-form-item prop="content">
            <t-editor ref="editorRef" class="editor" placeholder="请填写文章内容" :value="passage.content"  @inputContent="inputRichText"/>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" class="auto db"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { defineComponent, reactive, ref, toRefs } from "vue";
import usePersonalArticleList from "@/composable/personHome/usePersonalArticleList";
import useUploadFile from "@/composable/universal/useUploadFile";
import usePagination from "@/composable/universal/usePagination";
import useReleaseArticle from "@/composable/personHome/useReleaseArticle";
import TEditor from "@/components/TEditor"
const multipleTable=ref(null)
const isList = ref(true);
    const { articleData, queryParams, getArticleList,removeArticle,handleSelectionChange} = usePersonalArticleList();
    const preview = reactive({
      show: false,
      url: "",
    });
    const { imgs, uploadRef,fileChange,handlePicPreview,handleRemove} = useUploadFile(preview);

    const { handleSizeChange, handleCurrentChange } = usePagination( queryParams, getArticleList);

    const {editorRef,rules, passage,formRef,inputRichText,onSubmit } = useReleaseArticle(uploadRef,imgs,isList,getArticleList);
    
    const {list,totalCount}=toRefs(articleData)
</script>
<style lang="less" scoped>
.pagnation {
  margin-top: 20px;
  text-align: center;
}
.operate-container {
  height: 100%;
}
.el-upload-list__item-thumbnail {
  object-fit: cover;
}
:deep(.disable .el-upload--picture-card ){
  display: none;
}
.pic-item {
  width: 178px;
  height: 178px;
}
.preview-img {
  height: 100%;
  width: 100%;
}
:deep(.el-table__body),:deep(.el-table__header){
border-left: none;
border-right: none;
  margin-bottom: 0px  !important;
}
.my-article-item{
  width: 80px !important;
}
.heading_box{
  padding-bottom: 2px !important;
}
</style>
