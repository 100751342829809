<template>
  <!-- 会员等级 -->
  <div class="content">
    <table>
      <tr class="heading">
        <td>普通会员</td>
        <td>高级会员</td>
        <td>超级会员</td>
        <td>年度会员</td>
      </tr>
      <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          
          <td v-for="(item,index) in allMembers" :key="item.id">{{item.cost}}元/<span v-if="item.name==='年会员'">年</span><span v-else>月</span><br/>
          下载{{item.bidding}}条，超过下载上限，付费1元/条
          </td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>获取医信招投标、邀标、询价、竞谈、单一、竞价等招标采购信息服务获取包括项目名称在内公告内容等信息；</td>
          <td>获取医信招投标、邀价、询价、竞谈、单一、竞价等招标采购信息服务、获取包括项目名称在内公告内容等信息；</td>
          <td>获取医信招投标、邀价、询价、竞谈、单一、竞价等招标采购信息服务、获取包括项目名称在内公告内容等信息；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>项目动态：包含招标日期、采购单位、项目类别、金额等；</td>
          <td>项目动态；包含招标日期、截止日期、采购单位、项目类别、金额等；</td>
          <td>项目动态；包含招标日期、截止日期、采购单位、项目类别、金额等；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>可编辑电子表格；</td>
          <td>可编辑电子表格；</td>
          <td>可编辑电子表格；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>提供中标单位名称信息。</td>
          <td>可视化、可操作公告原地址链接；</td>
          <td>可视化、可操作公告原地址链接；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>细化公告项目内容、具化公告时间；</td>
          <td>细化公告项目内容、具化公告时间；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>提供中标单位名称信息；</td>
          <td>提供中标单位名称信息；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>将为购买一年以上的会员定制版用户特别赠送媒体推广服务礼包：即自购买日起1个自然年内，免费为用户提供医信邦官方网站（http://www.hitzone.cn/）1次广告推送（展示位置：医信邦官网首页“推荐”板块，以图文方式呈现，展示时间为7个工作日）；医信邦公众号（公众号名称：医信邦；微信号：hitzone-cn）次条版面（价值4000元）1次广告推送（注：公众号头次条广告推送时间需间隔30个工作日）。广告推广具体事宜由双方另行协商确定，协商不成的，视作乙方自愿放弃该权益。</td>
          <td>将为购买一年以上的会员定制版用户特别赠送媒体推广服务礼包：即自购买日起1个自然年内，免费为用户提供医信邦官方网站（http://www.hitzone.cn/）1次广告推送（展示位置：医信邦官网首页“推荐”板块，以图文方式呈现，展示时间为7个工作日）；医信邦公众号（公众号名称：医信邦；微信号：hitzone-cn）头条版面（价值8000元）1次广告推送、次条版面（价值4000元）1次广告推送（注：公众号头次条广告推送时间需间隔30个工作日）。广告推广具体事宜由双方另行协商确定，协商不成的，视作乙方自愿放弃该权益。</td>
        </tr>






      <!-- <tr>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td>
          获取医信招投标、邀标、询价、竞谈、单一、竞价等招标采购信息服务，获取包括项目名称在内公告内容等信息；
        </td>
        <td>
          获取医信招投标、邀价、询价、竞谈、单一、竞价等招标采购信息服务、获取包括项目名称在内公告内容等信息；
        </td>
        <td>
          获取医信招投标、邀价、询价、竞谈、单一、竞价等招标采购信息服务、获取包括项目名称在内公告内容等信息；
        </td>
      </tr>
      <tr>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td>项目动态：包含招标日期、采购单位、项目类别、金额等；</td>
        <td>
          项目动态：包含招标日期、截止日期、采购单位、项目类别、金额等；
        </td>
        <td>
          项目动态：包含招标日期、截止日期、采购单位、项目类别、金额等；
        </td>
      </tr>
      <tr>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td>可编辑电子表格；</td>
        <td>可编辑电子表格；</td>
        <td>可编辑电子表格；</td>
      </tr>
      <tr>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td>
          在相关企业同意提供排名信息的情况下，提供企业招标、中标前位排名（周报前5名、月报前10名排位）；
        </td>
        <td>可视化、可操作公告原地址链接；</td>
        <td>可视化、可操作公告原地址链接；</td>
      </tr>
      <tr>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td>提供中标单位名称信息。</td>
        <td>细化公告项目内容、具化公告时间；</td>
        <td>细化公告项目内容、具化公告时间；</td>
      </tr>
      <tr>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td>
          在相关企业同意提供排名信息的情况下，提供企业招标、中标前位排名（周报前5名、月报前10名排位）；
        </td>
        <td>
          在相关企业同意提供排名信息的情况下，提供企业招标、中标前位排名（周报前5名、月报前10名排位）；
        </td>
      </tr>
      <tr>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td>提供中标单位名称信息；</td>
        <td>提供中标单位名称信息；</td>
      </tr>
      <tr>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td class="not-provide"><i class="iconfont icon-false"></i></td>
        <td>
          将为购买一年以上的会员定制版用户特别赠送媒体推广服务礼包：
          即自购买日起1个自然年内，免费为用户提供医信邦官方网站（http://www.hitzone.cn/）1次广告推送（展示位置：医信邦官网首页“推荐”板块，以图文方式呈现，展示时间为7个工作日）；
          医信邦公众号（公众号名称：医信邦；微信号：hitzone-cn）头条版面1次广告推送、次条版面1次广告推送（注：公众号头次条广告推送时间需间隔30个工作日）。广告推广具体事宜由双方另行协商确定，协商不成的，视作乙方自愿放弃该权益。
        </td>
      </tr> -->
      <tr>
        <td class="bg_gray tc f16">免费</td>

        <td
          class="bg_l_yellow tc f16"
          v-for="item in allMembers"
          :key="item.id"
        >
          <button
            v-if="item.id===1"
            class="db auto"
            @click="showMemberForm(item.name)"
            :disabled="userInfo.level >= 1"
            :class="{ disable: userInfo.level >= 1 }"
          >
            购买
          </button>
          <button
            v-if="item.id===2"
            class="db auto"
            v-show="userInfo.level!=1"
            @click="showMemberForm(item.name)"
            :disabled="userInfo.level!=0"
            :class="{ disable: userInfo.level !=0 }"
          >购买</button>
          <button
            v-if="item.id===2"
            v-show="userInfo.level==1"
            class="db auto"
            @click="showMemberForm('升级')"
            :disabled="userInfo.level!==1"
            :class="{ disable: userInfo.level !=1 }"
          >升级</button>
          <button
            v-if="item.id===3"
            class="db auto"
            @click="showMemberForm(item.name)"
            :disabled="userInfo.level !==0"
            :class="{ disable: userInfo.level !==0 }"
          >
            购买
          </button>
          <p>文库{{item.library}}条/月 </p>
          <p>招投标数据{{item.bidding}}条/月</p>
        </td>
      </tr>
    </table>
  </div>
  <!-- 会员等级毕 -->
</template>
<script setup>
import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import $api from "@/api"
const store = useStore();
const userInfo = computed(() => store.state.userState.userInfo ?? {});
const showMemberForm = (type) => {
  store.commit("setOrderInfo", {
    title: "becomeMember",
    id: 0,
    price: 999,
    type: 4,
  });
  store.commit("toggleOrdinaryForm", false);
  console.log(type);
  switch (type) {
    case '高级会员':
      store.commit("toggleBecomeMemberForm", true)
      break;
    case '超级会员':

      sessionStorage.setItem('memberType', type)
      store.commit("toggleBecomeMemberForm", true)
      break;
    case '升级':
      store.commit("toggleUpdateMemberForm", true)
      break;
    default:
      sessionStorage.setItem('memberType', type)
      store.commit("toggleBecomeMemberForm", true)
      break;
  }
};

const allMembers = ref([])
const getAllMembers = async () => {
  const [err, data] = await $api.user.getAllMembers()
  if (!data.succeeded) return;
  allMembers.value = data.data
}

onMounted(getAllMembers)
</script>
<style lang="less" scoped>
.disable {
  background: #aba7a3;
  color: white;
  border-color: #aba7a3;
  cursor: not-allowed;
}
.disable:hover {
  background-color: #aba7a3 !important;
}
.not-provide {
  text-align: center;
}

// 2021 11 09
.heading td{width:241.5px;}
</style>
