<template>
  <div class="content">
    <div class="company">
      <el-form :model="form" ref="companyForm" :rules="rules">
        <el-form-item label="公司名字">
          {{ form.companyName }}
        </el-form-item>
        <el-form-item label="公司logo" class="bdb">
          <div v-if="form.logo" class="remote-logo">
            <el-image
              style="width: 60px; height: 60px;border-radius:50%;margin-right: 40px;"
              :src="getImgUrl(form.logo)"
              fit="cover"
            ></el-image>
          </div>
          <el-upload
            :limit="999"
            ref="uploadRef"
            :class="{ disable: hideUpload }"
            action="#"
            :auto-upload="false"
            :on-change="fileChange"
            list-type="picture"
            :file-list="fileList"
          >
            <el-button
              type="primary"
              class="upload-again"
              >重新上传</el-button
            >
          </el-upload>

          
          <span class="remark">图像格式为jpg\png，大小不超过2m</span>
        </el-form-item>

        <el-form-item>
          <div class="select_box">
            <el-select
              v-model="form.cate"
              multiple
              collapse-tags
              placeholder="请选择公司类型"
            >
              <el-option
                v-for="item in companyType"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>

            <el-select v-model="form.region" placeholder="所属省份">
              <el-option
                v-for="item in provinces"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>

            <el-select
              v-model="form.scale"
              placeholder="公司规模"
              style="margin-left:0px"
            >
              <el-option label="50人以下" value="50人以下"></el-option>
              <el-option label="50-100人" value="50-100人"></el-option>
              <el-option label="100-500人" value="100-500人"></el-option>
              <el-option label="500-1000人" value="500-1000人"></el-option>
              <el-option label="1000人以上" value=">000人以上"></el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item class="contact_box">
          <el-form-item class="contact-item" prop="contact">
            <template #label>
              <span class="label-style">联&ensp;系&ensp;人</span>
            </template>
            <el-input v-model="form.contact" placeholder="联系人"></el-input>
          </el-form-item>
          <el-form-item class="contact-item" prop="mb_phone" label="手机">
            <template #label>
              <span class="label-style">手&emsp;&emsp;机</span>
            </template>
            <el-input v-model="form.mb_phone" placeholder="手机"></el-input>
          </el-form-item>
          <el-form-item class="contact-item" prop="phone" label="联系电话">
            <template #label>
              <span class="label-style">联系电话</span>
            </template>
            <el-input v-model="form.phone" placeholder="联系电话"></el-input>
          </el-form-item>
          <el-form-item class="contact-item" prop="email" label="电子邮箱">
            <template #label>
              <span class="label-style">电子邮箱</span>
            </template>
            <el-input v-model="form.email" placeholder="联系邮箱"></el-input>
          </el-form-item>
        </el-form-item>

        <el-form-item prop="web_url">
          <el-input v-model="form.web_url" placeholder="网址"></el-input>
        </el-form-item>
        <el-form-item>
          <t-editor
            ref="editor"
            class="editor"
            types="products"
            :value="form.products"
            placeholder="请填写公司产品解决方案"
            @inputContent="inputRichText"
          />
        </el-form-item>
        <el-form-item prop="desc">
          <t-editor
            ref="editor"
            class="editor"
            types="desc"
            :value="form.desc"
            :max-length="1300"
            placeholder="请填写公司简介"
            @inputContent="inputRichText"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit()" class="auto db"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import useCompanyParams from "@/composable/personHome/useCompanyParams";
import useBecomeCompany from "@/composable/personHome/useBecomeCompany";
import useUploadFile from "@/composable/universal/useUploadFile";
import TEditor from "@/components/TEditor";
import { getImgUrl } from "@/utils/imageRelated";

const { userInfo } = defineProps({
  userInfo: {
    type: Object,
    default: () => {},
  },
});

const { form, provinces, rules, companyType, editor } = useCompanyParams(userInfo);

const { fileList, uploadRef, fileChange, hideUpload } = useUploadFile( {},()=>{form.logo=""});

const { companyForm,  onSubmit } = useBecomeCompany(
  userInfo,
  uploadRef,
  hideUpload,
  fileList,
  form,
  companyType
);

const inputRichText = (val, types) => form[types] = val;;
</script>
<style lang="less" scoped>
.operate-container {
  height: 100%;
}
:deep(.el-upload-list__item-thumbnail) {
  object-fit: cover;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.disable {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
:deep(.disable .el-upload--picture-card) {
  display: none;
}
:deep(.el-upload-list--picture) {
  display: inline-block;
}
:deep(.el-upload-list__item) {
  border: none;
}

:deep(.el-icon-close),
:deep(.el-icon-close-tip),
:deep(.el-upload-list__item-name) {
  display: none !important;
}
:deep(.el-form-item__content) {
  display: flex;
  align-items: center;
}
.bdb::after {
  content: "";
  display: block;
  clear: both;
  height: 100px;
}
:deep(.bdb .el-form-item__content) {
  position: absolute;
  height: 70px;
  overflow: hidden;
  margin-left: 100px;
}
.pic-item {
  width: 178px;
  height: 178px;
}
.preview-img {
  height: 100%;
  width: 100%;
}
.notshow {
  display: none;
}
.remote-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.upload-again {
  margin-bottom: 0;
  margin-left: 50px;
}
.bdb {
  align-items: center;
  padding-bottom: 20px;
}
.contact-item {
  width: 40%;
}
.label-style {
  font-size: 14px;
}
.select_box {
  display: flex;
  justify-content: space-between;
}
.editor {
  width: 100%;
}
</style>
