import {reactive,onMounted,ref} from "vue"
import $api from "@/api"
import Dialog from "@/utils/ui/dialog"
export default function usePersonalArticleList(){
    
    const queryParams=reactive({
        PageIndex:1,
        PageSize:15
    })
    const articleData=reactive({
        list:[],
        totalCount:0
    })
    const getArticleList=async ()=>{
        const [err,data]=await $api.user.getPersonalArticles(queryParams)
        if(!data.succeeded) return;
        articleData.list=data.data.items
        articleData.totalCount=data.data.totalCount
    }
    const checkList=ref([])
    const handleSelectionChange=(val)=>{
        checkList.value=val.map(e=>e.id)
      }
    const removeArticle=async()=>{
        if(!checkList.value.length){
            Dialog.alert("提示","您还未选择待删除文章",true,true)
        }else{
            const [err,data]=await $api.user.removeArticle(checkList.value)
            getArticleList()

        }
    }

    onMounted(getArticleList)
    return {
        articleData,
        queryParams,
        getArticleList,
        checkList,
        removeArticle,
        handleSelectionChange
    }
}