<template>
  <div class="content">
    <ul class="order_list">
      <li>
        <dl class="order_heading">
          <dd>序号</dd>
          <dd>订单名称</dd>
          <dd>下单时间</dd>
          <dd>订单金额（元）</dd>
          <dd>支付状态</dd>
        </dl>
      </li>
      <li v-for="(item, index) in orderData.list" :key="item.id">
        <dl>
          <dd>
            {{
              queryParams.PageSize * queryParams.PageIndex -
                (queryParams.PageSize - index - 1)
            }}
          </dd>
          <dd class="otw">
            {{ item.name }}
          </dd>
          <dd>{{ item.orderTime }}</dd>
          <dd>¥{{ item.money }}</dd>
          <dd>{{ item.payState }}</dd>
        </dl>
      </li>
    </ul>
    <div class="pagnation">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.PageIndex"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryParams.PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="orderData.totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script setup>
import { defineComponent, onMounted, reactive, ref } from "vue";
import usePagination from "@/composable/universal/usePagination";
import $api from "@/api";

const queryParams = reactive({
  PageIndex: 1,
  PageSize: 15,
});
const orderData = reactive({
  list: [],
  totalCount: 0,
});
const getOrderList = async () => {
  const [err, data] = await $api.user.getOrder(queryParams);
  if (!data.succeeded) return;
  orderData.list = data.data.items;
  orderData.totalCount = data.data.totalCount;
};
const { handleSizeChange, handleCurrentChange } = usePagination(queryParams,getOrderList);
onMounted(getOrderList);
</script>
<style lang="less" scoped>
.pagnation {
  margin-top: 50px;
  text-align: center;
}

// 2021 11 09
.order_heading{background: #409eff;color:#fff;}
</style>
