<template>
  <div class="content">
    <div class="list_2 pt10">
      <el-form :model="settingForm">
        <span class="f18  mr10">个人头像</span
        ><span class="remark">(图像格式为jpg\png，大小不超过2m)</span>
        <el-form-item class="bdb">
          <div v-if="settingForm.image" class="remote-logo">
            <el-image
              style="width: 60px; height: 60px;border-radius:50%;margin-right: 40px;"
              :src="settingForm.image"
              fit="cover"
            ></el-image>
          </div>
          <el-upload
            :limit="999"
            ref="uploadRef"
            :class="{ disable: hideUpload }"
            action="#"
            :auto-upload="false"
            :http-request="uploadImg"
            :on-change="fileChange"
            list-type="picture"
            :file-list="fileList"
          >
            <el-button type="primary" class="upload-again">重新上传</el-button>
          </el-upload>
          
        </el-form-item>

        <el-form-item class="contact_box">
          <el-input
            v-model="settingForm.nickName"
            placeholder="昵称(如未填写将随机生成)"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            type="textarea"
            v-model="settingForm.introduction"
            placeholder="自我简介"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit()"
            class="auto db"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { computed, reactive, toRaw } from "vue";
import { useStore } from "vuex";
import useUploadFile from "@/composable/universal/useUploadFile";
import uploadImg from "@/utils/file/uploadImg"
import { getImgUrl } from "@/utils/imageRelated";
import $api from "@/api";
import dialog from "@/utils/ui/dialog";
const store = useStore();
const userInfo = computed(() => store.state.userState.userInfo || {});
const settingForm = reactive({
  image: getImgUrl(userInfo.value.image),
  nickName: userInfo.value.nickName,
  introduction: userInfo.value.introduction,
});

const { uploadRef, fileChange, hideUpload, fileList } = useUploadFile(
  {},
  () => {settingForm.image = ""}
);
const onSubmit = async () => {
   const header_url = await uploadImg(fileList.value.map(e=>e.raw),userInfo.value.image);
  const { nickName, introduction } = settingForm;
  const payloadName=nickName||`医信人${userInfo.value.phone.slice(-4)}`
  const payloadDesc=introduction||"这个人很懒,什么都没有留下"
  const [err, data] = await $api.user.editBaseInfo({
      nickName:payloadName,
      introduction:payloadDesc,
      image: header_url }
  );
  if (!data.succeeded) return;
  dialog.message("success", "修改成功", 2000,()=>{
    store.dispatch("getUserInfo").then((res) => {
          store.commit("setUserInfo", res);
        })
  });
};
</script>
<style lang="less" scoped>
.operate-container {
  height: 100%;
}
:deep(.el-upload-list__item-thumbnail) {
  object-fit: cover;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.disable {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
:deep(.disable .el-upload--picture-card) {
  display: none;
}
:deep(.el-upload-list--picture) {
  display: inline-block;
}
:deep(.el-upload-list__item) {
  border: none;
}

:deep(.el-icon-close),
:deep(.el-icon-close-tip),
:deep(.el-upload-list__item-name) {
  display: none !important;
}
:deep(.el-form-item__content) {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.pic-item {
  width: 178px;
  height: 178px;
}
.preview-img {
  height: 100%;
  width: 100%;
}
.notshow {
  display: none;
}
.remote-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.upload-again {
  margin-bottom: 0;
  margin-left: 50px;
}
.bdb::after {
  content: "";
  display: block;
  clear: both;
  height: 100px;
}
:deep(.bdb .el-form-item__content) {
  position: absolute;
  height: 70px;
  overflow: hidden;
}
</style>
