<template>
      <div class="container">
      <div class="personal_banner">
    <div class="person_info">
      <a class="profile db" href="javascript:void(0);">
          <img class="db" v-if="userInfo.image==null" src="@/assets/images/tx.jpeg"/>
           <img class="db" v-else :src="getImgUrl(userInfo.image)"/>
         </a> 
      <div class="intro">
        <div class="name">
            <span class="cfff f20 vm" v-if="userInfo.nickName==null">医信人</span
          >
          <span class="cfff f20 vm" v-else>{{userInfo.nickName}}</span
          ><span class="c999 f20 vm">|</span><span class="f16 vm">{{getMemberDesc(userInfo.level)}}</span
          ><i class="iconfont icon-crown f16"></i>
        </div>
        <div class="brief">{{userInfo.introduction}}</div>
      </div>
    </div>
  </div>
  <div class="personal" id="personal">
    <div class="sb_box">
    </div>
          <el-tabs v-model="menu.activeName" tab-position="left" style="height: 200px;width:1200px;display:flex;justify-content:space-between" :before-leave="mayToggle" @tab-click="toggleMenu">
        <el-tab-pane v-for="item in menu.items" :class="{ active: item.active }" :label="item.label" :name="item.componentName">
            <template #label >
                <span class="custom-label">
                    <svg class="icon" aria-hidden="true">
                            <use :xlink:href="`#icon-${item.icon}`"></use>
                        </svg>{{item.label}}</span>
                </template>
                <component v-if="!! userInfo && item.active" :is="activeComponent" :user-info="userInfo"></component>
        </el-tab-pane>
  </el-tabs>
  </div>
  </div>
</template>
<script setup>
import {reactive,defineAsyncComponent, computed, watchEffect, ref,onMounted} from "vue"
import {useStore} from "vuex"
import { useRouter} from "vue-router"
import {getImgUrl} from "@/utils/imageRelated"
const files=require.context("./components",false,/\.vue$/)
let components={}
files.keys().forEach(key=>{
    components[key.replace(/(\.\/|\.vue)/g,"")]=defineAsyncComponent(()=>import(`./components/${key.replace(/\.\//g,"")}`))
})
      const store=useStore()
      const router=useRouter()
      const isLogin =computed(()=>!!store.state.userState.token)
      const userInfo=computed(()=>store.state.userState.userInfo || {})
      const isCompany=ref("")
      const menu=reactive({
          items:[
              {label:"我的订单",icon:"personal-order",componentName:"myOrder",active:true},
            //   {label:"会员等级",icon:"personal-member",componentName:"myMember",active:false},
              {label:isCompany,icon:"company",componentName:"myCompany",active:false},
              {label:"发布文章",icon:"personal-article",componentName:"myArticle",active:false},
              {label:"个人设置",icon:"personal-setting",componentName:"mySetting",active:false},
              {label:"密码设置",icon:"password",componentName:"myPassword",active:false},
          ],
          activeName:"myOrder"
      })
    //#region 动态编辑tab
      watchEffect(()=>{
          if(!store.state.userState.token){
          menu.items=menu.items.map((e,i)=>{
              if(!i){
                  e.active=true;
                  menu.activeName=e.componentName;
              }else{
                  e.active=false
              }
              
            return e
          }).filter(e=>e.label!='发布文章')
      }
      const vendorsId=userInfo.value?.vendorsId??0;
          !!vendorsId?isCompany.value="厂商信息":isCompany.value="成为厂商";
          if(!userInfo.value?.vendorsId){
              menu.items=menu.items.filter(e=>e.label!=="发布文章")
          }else{
              menu.items=menu.items.filter(e=>e.label!=="个人设置")
          }
      })
      //#endregion

    //#region tab切换事件
      const mayToggle=(to,from)=>{
          if(to==="myOrder"){
            return true
          }else{
            return isLogin.value
          }
      };
      const toggleMenu=({props})=>{
          if(!isLogin.value){
            store.commit("toggleLoginForm",true)
            return false;
          }else{
                if(props.label==="成为厂商"){
                    router.push({
                        name:"CertCompany"
                    })
                }
                menu.items.forEach((e,i)=>{
                if(e.label===props.label){
                    e.active=true
                    menu.activeName=props.name
                }else{
                    e.active=false
                }
            })
         }
      }
      //#endregion
     
      const getMemberDesc=(level)=> !level?"普通会员":level===1?"高级会员":level === 2?"超级会员":"年度会员"
      const activeComponent=computed(()=>components[menu.activeName])

      console.log(userInfo.image)
</script>
<style lang="less" scoped>

:deep(.el-tabs--left){
    height: auto !important;
}
:deep(.el-tabs__header){
    background: white;
    border-radius: 4px;
    box-shadow: 0px 1px 100px 0px #c9c9c952;
}
:deep(.el-tabs__content){
    width: 967px;
}
:deep(.el-tabs__item){
    height: 50px;
    line-height: 50px;
    padding: 0 40px !important;
    text-align: center !important;
    background-color: #ffffff00;
    border-bottom: 1px solid #E6E6E6 !important;
}
:deep(.el-tabs__item):hover{
    background: #409eff;
    color: white !important;
}
:deep(.is-active){
    background: #409eff;
    color: white !important;
}
:deep(.el-tabs__active-bar){
    display: none;
}
:deep(.el-tabs__nav-wrap)::after{
    width: 0px !important;
}

#personal{
    justify-content: start;
}
.icon{
    width: 18px;
    height: 18px;
    margin-right: 3px;
}
.custom-label{
    font-size: 18px;
}
.name{
    display: flex;
    align-items: center;
}
</style>
