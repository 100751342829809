import {ref} from "vue"
import $api from "@/api"
import Dialog from "@/utils/ui/dialog"
import uploadImg from "@/utils/file/uploadImg"
export default function useBecomeCompany(userInfo,uploadRef,hideUpload,fileList,form,companyType ) {
    const companyForm = ref(null)
    const onSubmit = () => {
        companyForm.value.validate(async(valid) => {
            if (!valid) {
                return false
            } else {
                const logoUrl = await uploadImg(fileList.value.map(e=>e.raw));
                if(logoUrl){
                    hideUpload.value=true
                }
                const params={
                    "id": userInfo.vendorsId,
                    "companyName": form.companyName,
                    "companyLogo": logoUrl || form.logo,
                    "images": "",
                    "vendorsId": form.cate.reduce((prev,next)=>{
                        if(companyType.value.some(e=>e.label===next)){
                            prev.push(companyType.value.find(e=>e.label===next).id)
                        }
                        return prev;
                    },[]),
                    "companyAddress": form.region,
                    "companyProfile": form.desc,
                    "companyProducts": form.products,
                    "companySize": form.scale,
                    "contacts": form.contact,
                    "phones": form.mb_phone,
                    "telPhones": form.phone,
                    "email": form.email,
                    "url": form.web_url,
                    "address": form.region
                  }
                const [err,data]=await $api.company.modifyCompanyInfo(params)
                if(!data.succeeded){
                    Dialog.alert("提示",data.errors,true,true)
                }else{
                  Dialog.alert("提示","修改成功",true,false)
                }
            }
        })

    }
    return {
        companyForm,
        uploadImg,
        onSubmit,
    }
}