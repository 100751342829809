<template>
  <div class="content">
    <div class="list_2 pt10">
      <el-form :model="password" ref="passwordRef">
        <span class="f18 mr10">密码设置</span
        ><span class="remark">(密码为8~20位字母+数字)</span>
        <el-form-item class="password_box old-pwd">
          <template #label>
            <span>旧&ensp;密&ensp;码</span>
          </template>
          <el-input
            v-model="password.oldPassword"
            placeholder="旧密码"
            type="password"
          ></el-input>
          <span class="tips">*旧密码长度8位</span>
        </el-form-item>
        <el-form-item class="password_box">
          <template #label>
            <span>新&ensp;密&ensp;码</span>
          </template>
          <el-input
            v-model="password.newPassword"
            placeholder="新密码"
            type="password"
          ></el-input>
        </el-form-item>

        <el-form-item class="password_box">
          <template #label>
            <span>重复密码</span>
          </template>
          <el-input
            v-model="password.repeatPwd"
            placeholder="新密码确认"
            type="password"
          ></el-input>
        </el-form-item>

        <el-form-item class="btn">
          <el-button
            type="primary"
            @click="onSubmit"
            :disabled="disable"
            class="auto db"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { computed, reactive,ref } from "vue";
import $api from "@/api";
import Dialog from "@/utils/ui/dialog";

const passwordRef = ref(null);
const password = reactive({
  oldPassword: "",
  newPassword: "",
  repeatPwd: "",
});
const disable = computed(
  () =>
    password.oldPassword.length < 7 ||
    password.newPassword.length < 7 ||
    password.newPassword != password.repeatPwd
);
const onSubmit = async () => {
  const params = {
    password: password.oldPassword,
    newPassword: password.newPassword,
  };
  const [err, data] = await $api.user.changePassword(params);
  Dialog.alert("提示", data.errors || "修改成功,请妥善保存", true, true, () => {
    passwordRef.value.resetFields();
  });
};
</script>
<style lang="less" scoped>
.btn {
  text-align: center;
}
.old-pwd {
  width: 450px !important;
}
.tips {
  margin-left: 8px;
  font-size: 12px;
  color: #cb5b5b;
}
</style>
